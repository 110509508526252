<script setup lang="ts">
import { useCmsBlock } from "#imports";
import type {CmsBlockMetrics} from "~/types/cmsBlockTypes";

const props = defineProps<{
  content: CmsBlockMetrics;
}>();

const { getSlotContent } = useCmsBlock(props.content);

const leftContent = getSlotContent("left");
const rightContent = getSlotContent("right");
</script>

<template>
  <v-row no-gutters align="center">
    <v-col cols="12" md="7" order="2" order-md="1" class="px-4 px-md-16 py-4">
      <CmsGenericElement :content="leftContent" />
    </v-col>
    <v-col cols="12" md="5" order="1" order-md="2" class="relative">
      <CmsGenericElement :content="rightContent" />
      <v-img class="decal" src="/logomark.svg"></v-img>
    </v-col>

  </v-row>
</template>

<style scoped>
.decal {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-56%);
  display: block;
  width: 445px;
  z-index: 1;
  pointer-events: none;
}

@media screen and (min-width: 600px) {
  .decal {
    transform: translateX(-75%);
  }
}

@media screen and (min-width: 1280px) {
  .decal {
    transform: translateX(-95%);
  }
}


</style>
